.login-form .prime-card {
  min-width: 350px;
  max-width: 600px;
}
.login-form .prime-card form {
  margin-top: 2rem;
}
.login-form .prime-card .p-field {
  margin-bottom: 1.5rem;
}
@media screen and (max-width: 960px) {
  .login-form .prime-card {
      width: 80%;
  }
}
@media screen and (max-width: 640px) {
  .login-form .prime-card {
      width: 100%;
      min-width: 0;
  }
}

.prime-card {
  background: var(--surface-e);
  padding: 2rem;
  -webkit-box-shadow: 0 2px 1px -1px rgba(0,0,0,.2),0 1px 1px 0 rgba(0,0,0,.14),0 1px 3px 0 rgba(0,0,0,.12);
  box-shadow: 0 2px 1px -1px rgba(0,0,0,.2),0 1px 1px 0 rgba(0,0,0,.14),0 1px 3px 0 rgba(0,0,0,.12);
  border-radius: 4px;
  margin: 2rem 0 2rem 0;
}