.container {
  display: flex; /* or inline-flex */
}

.editor-select{
	padding-bottom: 20px;
}

.publish-button{
	padding-top: 20px;
}

.course-elements {
  display: flex; 
  justify-content: normal;
} 

.selectbox {
  flex: 1 1 auto;
}

.p-dropdown {
  display: flex;
  flex: 1;
  width: 180px;
}

.top-group {
  display: flex;
  justify-content: flex-end;
}

.top-elements {
  flex: 0 0; 
  min-width: 120px;
  max-width: 250px;
  margin-left: 10px;
  margin-bottom: -1px;
}

.editor-select {
  display: flex;
}

.course-tree{
  margin-top: 37px;
}

.p-tree .p-tree-container{
  height: 612px;
}

.p-tree-toggler {
  min-width: 2rem;
}

.course-attributes {
  padding-top: 20px;
  text-align: left;
}