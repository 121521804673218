.WebView {
  display: block; /* or inline-flex */
  width: 900px;
}

.title{
  display: flex;
}

.left-title{
  margin-left: 470px;
}

.active-area{
  display: flex;
}

.editor-select{
	padding-bottom: 20px;
}

.course-tree{
  flex:1 1 auto;
  margin-top: 0;
  margin-right: 10px;
}

.preview {
  flex:1 1 auto;
}

.select-course{
  flex-grow: 1;
  width: 250px;
}

.p-tree .p-tree-container{
  height: 740px;
}