img {
  width: 375px;
  border:0;
  outline:none;
}
img.card{
  border:0;
  outline:none;
  margin-bottom: 17px;
  margin-left:-15px; /* to deal with padding of parent' */
}

ul.card{
  list-style-image: url('images/list_item.png');
}

img.answer-button {
  content: url('images/quiz_answer_button.png')
}


.Card-preview h1 {
  text-align: center;
  font-weight: 600;
  font-size: 24px;
  line-height: 30px;
}

.Card-preview h2 {
  text-align: center;
  font-weight: 600;
  font-size: 22px;
  line-height: 28px;
}

.Card-preview h3 {
  text-align: center;
  font-weight: 600;
  font-size: 20px;
  line-height: 26px;
}

.button-container{
  display: flex;
  align-items: center;
  justify-content: center;
}

.Card-preview button {
  background-image: url(images/card_button.png);
  background-size: 197px 54px;
  height: 54px;  
  width: 197px;
  border: 0;
  text-align: center;
  text-decoration: none;
  color: white;
  text-align: center;
}

.answers {
  position: relative;
  text-align: left;
  bottom: 0;
}

.text {
  position: absolute;
  top: 20%;
  left: 10%;
}

.Card-preview-wrapper{
  width:375px;
  height: 812px;
  background-color: #DCDCDC;
  position: relative;
  display: flex;
  outline: 2px solid #DCDCDC;
}

.Card-preview {
  max-height: 712px;
  overflow-y: scroll;
  margin-top: 100px;
  padding-top: 10px;
  padding-left: 15px;
  padding-right: 21px;
  font-family: Noto Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 30px;
}

.status-bar {
  z-index: 2;
  position: absolute;
  margin-top: 5px;
}

.progress-counter {
  position: absolute;
  width: 375px;
  margin-top: 67px;
  text-align: center;

  font-size: 12px;
  line-height: 24px;
  /* or 200% */
  text-align: center;
  color: #808080;
}

.cover-image {
  position: absolute;
  z-index: 1;
}

img.cover-image {
  width: 375px;
}

.card-background {
  position: absolute;
  margin-top: 64px;
  width: 375px;
  z-index: 2;
}

.card-content{
  width:375px;
  z-index: 10;
}


.note-container {
  padding-left: 35px;
  margin-bottom: 20px;
}

.note-header{
  border: deepskyblue;
}

img.note {
  width: 75px;
  float: left;
}
p.note {
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  text-transform: uppercase;
  line-height: 50px;
  margin-bottom: 0px;
}
.note-body {
  margin-top: 10px;
  font-family: Noto Sans;
  font-style: italic;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
}