img {
  width: 339px;
  border:0;
  outline:none;
}

.cover-image-container {
  width: 339px;
  height: 120px;
  overflow: hidden;
}

.course-card {
  border-radius: 10px;
  overflow: hidden;
  box-shadow: 6px 6px 8px rgba(74, 74, 74, 0.1);
  margin-bottom: 10px;
}

.course-name{
  font-weight: 500;
  font-size: 18px;
  line-height: 18px;
}

.course-data{
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 24px;
}

.description {
  margin-top: 6px;
  margin-left: 20px;
}