.question-editor {
  display: flex;
}
.questions.sideBar {
  width: 20%;
}

.title{
  display: flex;
  justify-content: center;
  margin-top: 20px;
}

.questions.content {
  flex: 1 0 auto;
  margin-left: 20px;
}

.question-editor .p-inputtext {
  margin-bottom: 10px;
  width: 80%;
}

.question-editor .p-inputgroup {
  width: 80%;
  margin-bottom: 15px;
}

.question-editor .p-button-outlined {
  margin-bottom: 10px;
} 

.category-icon {
  max-width: 40px;
  margin-left: 10px;
}